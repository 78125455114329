import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Image,
  Row,
  Tab,
  Tabs,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
// This bellow CSS is necessary for YARN package "font-awesome": "^4.7.0",
import "font-awesome/css/font-awesome.min.css";
import DatePicker from "react-datepicker";
import { format, subYears } from "date-fns";

// Query
import FIND_AIRDROPS from "./query/findAirdropHistory.query";

// MUTATIONS

import AIRDROP_MULTIPLE_NFTS from "./mutation/nft/airdropMultipleNfts.mutations";

import SEND_TWO_FACTOR from "./mutation/createTwoFactorVerification.mutaion";
import { type } from "@testing-library/user-event/dist/type";
import History from "./pages/history";

// STYLE;
import "react-datepicker/dist/react-datepicker.css";
const style = {
  containerStyle: {
    marginTop: "20px",
  },
  selectedCountry: {
    margin: "10px",
  },
};
export default function App() {
  // Query
  // const { loading, error, data, refetch } = useQuery(FIND_SOCCER_MATCH, {
  //   variables: { _id: matchId },
  //   skip: gameName != "soccerSingle" && gameName != "soccerSeries",
  // });

  // QUERY
  const { data, loading, error, refetch } = useQuery(FIND_AIRDROPS, {
    variables: {
      who: "all",
    },
  });

  // MUTATIONS
  const [
    airdropMultipleNfts,
    { loading: loadingAirdropMultipleNfts, error: errorAirdropMultipleNfts },
  ] = useMutation(AIRDROP_MULTIPLE_NFTS);

  const [
    createTwoFactorVerification,
    { loading: loadingTwoFactor, error: errorTwoFactor },
  ] = useMutation(SEND_TWO_FACTOR);

  const NFT_COUNTRIES = [
    "Qatar",
    "Ecuador",
    "Senegal",
    "Netherlands",
    "England",
    "Iran",
    "USA",
    "Wales",
    "Argentina",
    "Saudi Arabia",
    "Mexico",
    "Poland",
    "France",
    "Australia",
    "Denmark",
    "Tunisia",
    "Spain",
    "Costa Rica",
    "Germany",
    "Japan",
    "Belgium",
    "Canada",
    "Portugal",
    "Uruguay",
    "Brazil",
    "Cameroon",
    "Croatia",
    "Ghana",
    "Morocco",
    "Serbia",
    "South Korea",
    "Switzerland",
  ];

  const WOMEN_NFT_2023_COUNTRIES = [
    "New Zealand",
    "Norway",
    "Philippines",
    "Switzerland",
    "Australia",
    "Ireland",
    "Nigeria",
    "Canada",
    "Spain",
    "Costa Rica",
    "Zambia",
    "Japan",
    "England",
    "Haiti",
    "Denmark",
    "China",
    "USA",
    "Vietnam",
    "Netherlands",
    "Portugal",
    "France",
    "Jamaica",
    "Brazil",
    "Panama",
    "Sweden",
    "South Africa",
    "Italy",
    "Argentina",
    "Germany",
    "Morocco",
    "Colombia",
    "South Korea",
  ];

  const NFT_COLLECTION_NAMES = [
    "Women's World Cup 2023 Collection",
    "Men's World Cup 2022 Collection",
  ];

  const NFT_CCOLLECTIONS = {
    WOMENS_WORLD_CUP_2023: "Women's World Cup 2023 Collection",
    MENS_WORLD_CUP_2022: "Men's World Cup 2022 Collection",
  };

  // State
  const [publicKeyToSendNFT, setPublicKeyToSendNFT] = useState(null);
  const [giftMessage, setGiftMessage] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [identity, setIdentity] = useState(false);
  const [identifyPerson, setIdentifyPerson] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [enteredCode, setEnteredCode] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(
    NFT_CCOLLECTIONS.WOMENS_WORLD_CUP_2023
  );

  // Birth date
  let currentDate = new Date();
  let thirtyYearsAgo = subYears(currentDate, 30);
  const [startDate, setStartDate] = useState(thirtyYearsAgo);

  // Max birthdate
  const fiveyearsAgo = subYears(new Date(), 5);

  const [selectPage, setSelectPage] = useState("airdrop");

  // Multiple Country & amount State
  const [countries, setCountries] = useState([]);
  const [totalNft, setTotalNft] = useState(0);
  const listingNumber = Array.from(Array(20).keys());

  async function sendTwoFactor() {
    if (identifyPerson == "") {
      setAlertMessage("Please Enter Your Identity...");
    }
    const { data } = await createTwoFactorVerification({
      variables: {
        type: "airdrop",
        who: identifyPerson,
      },
    });

    if (data.createTwoFactorVerification.successMessage) {
      setAlertMessage(data.createTwoFactorVerification.successMessage);
      setIdentity(true);
    } else if (data.createTwoFactorVerification.errorMessage) {
      setAlertMessage(data.createTwoFactorVerification.errorMessage);
      setIdentity(false);
    }
  }

  async function mintNft(event) {
    event.preventDefault();
    if (enteredCode == null || enteredCode == "") {
      window.alert("Please enter the verification code");
      return false;
    } else if (receiverEmail == null || receiverEmail == "") {
      window.alert("Please provide the email of receiver");
      setIdentity(true);
    } else if (countries.length === 0) {
      window.alert("Select country and amount");
      return null;
    } else {
      const checkMail = validateEmail(receiverEmail);
      if (!checkMail) {
        window.alert("You typed invalid mail");
        return null;
      }
      let dateOfBirth = format(
        startDate ? startDate : new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );
      const { data } = await airdropMultipleNfts({
        variables: {
          collectionType:
            selectedCollection == NFT_CCOLLECTIONS.MENS_WORLD_CUP_2022
              ? "Male"
              : "Female",
          verificationCode: Number(enteredCode),
          who: identifyPerson,
          toPublicKey: publicKeyToSendNFT,
          receiverEmail,
          nfts: countries,
          giftMessage: giftMessage ? giftMessage : null,
        },
      });
      if (data.airdropMultipleNfts.errorMessage) {
        window.alert(data.airdropMultipleNfts.errorMessage);
      } else if (data.airdropMultipleNfts.successMessage) {
        await refetch();
        window.alert(data.airdropMultipleNfts.successMessage);
      }
    }
  }

  function removeItem(item) {
    setTotalNft(totalNft - item.amount);
    let selectItem = countries;
    selectItem = selectItem.filter((i) => i != item);
    setCountries(selectItem);
  }

  async function addCountry(c, a) {
    let checkCountry = JSON.parse(JSON.stringify(countries));
    console.log(countries, "oiooi");
    let commonCountry = false;
    for (let i = 0; i < checkCountry.length; i++) {
      if (checkCountry[i].country === c) {
        commonCountry = true;
        checkCountry[i].amount = checkCountry[i].amount + a;
      }
    }
    if (commonCountry) {
      setCountries(checkCountry);
    } else {
      setCountries(() => {
        return [...countries, { country: c, amount: a }];
      });
    }
    setTotalNft(totalNft + a);
  }

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  // WINDOW SCREEN SIZE CHANGE
  useEffect(() => {
    // Your Code to fetch
    return () => {
      //   window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  // LOADING PREVIEW
  // if (loading)
  //   return (
  //     <div>
  //       <form>
  //         <label>
  //           Name:
  //           <input type="text" name="name" />
  //         </label>
  //         <input type="submit" value="Submit" />
  //       </form>
  //     </div>
  //   );

  if (!identity) {
    return (
      <div className="container">
        <h3
          style={{ fontWeight: "bold", textAlign: "center", marginTop: "30px" }}
        >
          BasementSports Airdrop NFT!
        </h3>
        <div style={{ alignItems: "center" }}>
          <div className="my-3 mx-5">
            <Form.Label>Please Identify yourself</Form.Label>
            <Form.Control
              type="text"
              placeholder="Input Identity"
              onChange={(event) => setIdentifyPerson(event.target.value)}
            />
          </div>
          <Button
            onClick={() => sendTwoFactor()}
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "40px",
            }}
            variant="success"
            type="submit"
          >
            {loadingTwoFactor ? "Code Sending" : " Send code"}
          </Button>
        </div>
        <h6>{alertMessage}</h6>
      </div>
    );
  }

  return (
    <Container style={style.containerStyle}>
      <div className="my-2">
        <Button
          onClick={() => setSelectPage("airdrop")}
          className="bg-dark w-50 border-0"
        >
          <h5 className="my-auto">Airdrop</h5>
        </Button>

        <Button
          onClick={() => setSelectPage("history")}
          style={{ width: "47%", marginLeft: "2%" }}
          className="bg-dark  border-0"
        >
          <h5
            className="my-auto"
            onClick={() => {
              refetch();
            }}
          >
            History
          </h5>
        </Button>
      </div>

      {selectPage === "airdrop" && (
        <>
          <h6>{alertMessage}</h6>
          <Row>
            <Col>
              <Form onSubmit={mintNft}>
                {identity && (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Enter the 2FA code <b style={{ color: "red" }}>*</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the 2FA code"
                      onChange={(event) => setEnteredCode(event.target.value)}
                    />
                  </Form.Group>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>
                    Receiver Email <b style={{ color: "red" }}>*</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Receiver Email"
                    onChange={(event) => setReceiverEmail(event.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Receiver Birth Date (optional)</Form.Label> <br />
                  <DatePicker
                    className="birthDateStyle"
                    selected={startDate}
                    onChange={(date) => {
                      console.log("Amara: ", date);
                      setStartDate(date);
                    }}
                    style={{ backgroundColor: "red", color: "red" }}
                    placeholderText="Select a date"
                    maxDate={fiveyearsAgo}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>To Public Address (optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="To Public Key"
                    onChange={(event) =>
                      setPublicKeyToSendNFT(event.target.value)
                    }
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Airdrop message (optional)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Airdrop message"
                    onChange={(event) => setGiftMessage(event.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Choose NFT Collection <b style={{ color: "red" }}>*</b>
                  </Form.Label>{" "}
                  <br />
                  <Dropdown className="p-0" as={ButtonGroup}>
                    <Button variant="secondary">
                      <h6 className=" avnirnextregular text-white mt-1">
                        {selectedCollection}
                      </h6>
                    </Button>
                    <Dropdown.Toggle
                      split
                      variant="secondary"
                      id="dropdown-split-basic"
                    />
                    <Dropdown.Menu>
                      {NFT_COLLECTION_NAMES.map((collectionName, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={(event) => {
                              setCountries([]);
                              setTotalNft(0);
                              setSelectedCollection(collectionName);
                            }}
                          >
                            {collectionName}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>

                <div>
                  <h4 style={{ textAlign: "center" }}>
                    Choose Country and Amount <b style={{ color: "red" }}>*</b>
                  </h4>

                  {/* MENs countries */}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {selectedCollection ==
                      NFT_CCOLLECTIONS.MENS_WORLD_CUP_2022 &&
                      NFT_COUNTRIES.map((country, index) => (
                        <Dropdown
                          className="pe-2  py-2"
                          key={index}
                          as={ButtonGroup}
                        >
                          <Button variant="dark">
                            <h6 className=" avnirnextregular text-white mt-1">
                              {country}
                            </h6>
                          </Button>
                          <Dropdown.Toggle
                            split
                            variant="dark"
                            id="dropdown-split-basic"
                          />
                          <Dropdown.Menu>
                            {listingNumber.map((number, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  onClick={(event) => {
                                    setTotalNft(totalNft + number + 1);
                                    addCountry(country, number + 1);
                                  }}
                                >
                                  {number + 1}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {selectedCollection ==
                      NFT_CCOLLECTIONS.WOMENS_WORLD_CUP_2023 &&
                      WOMEN_NFT_2023_COUNTRIES.map((country, index) => (
                        <Dropdown
                          className="pe-2 py-2"
                          key={index}
                          as={ButtonGroup}
                        >
                          <Button variant="dark">
                            <h6 className=" avnirnextregular text-white mt-1">
                              {country}
                            </h6>
                          </Button>
                          <Dropdown.Toggle
                            split
                            variant="dark"
                            id="dropdown-split-basic"
                          />
                          <Dropdown.Menu>
                            {listingNumber.map((number, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  onClick={(event) => {
                                    setTotalNft(totalNft + number + 1);
                                    addCountry(country, number + 1);
                                  }}
                                >
                                  {number + 1}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      ))}
                  </div>

                  {/* Total Amount selected */}
                  <h4 style={{ textAlign: "center", marginTop: "50px" }}>
                    {" "}
                    Total NFT selected {totalNft}
                  </h4>

                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {countries.map((c, index) => (
                      <div
                        key={index}
                        className="d-flex bg-secondary justify-content-center align-items-center  m-2 rounded"
                      >
                        <h5 className="p-2 text-white small" key={index}>
                          {c.country}-{c.amount}
                        </h5>

                        <p
                          className="onHover"
                          onClick={() => removeItem(c)}
                          style={{ color: "white", marginRight: "3px" }}
                        >
                          X
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <Button
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "35px",
                    marginBottom: "50px",
                  }}
                  disabled={!identity}
                  variant="primary"
                  type="submit"
                  className="px-5"
                >
                  {loadingAirdropMultipleNfts ? "Processing" : "Airdrop"}
                </Button>
              </Form>
            </Col>
          </Row>
        </>
      )}

      {selectPage === "history" && <History />}
    </Container>
  );
}
