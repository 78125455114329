import { gql } from "@apollo/client";

export default gql`
  query findAirdropHistory($who: String) {
    findAirdropHistory(who: $who) {
      _id
      who
      nftIds
      nftNames
      finalPublicWalletAddressToSendNfts
      receiverEmail
      countryWiseAmounts {
        amount
        country
      }
      nftIdsDetails {
        url
        nftName
        nftName
      }
      isExistCryptoEncyptedPrivateKey
      collectionType
      createdAt
    }
  }
`;
