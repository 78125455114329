import { gql } from "@apollo/client";

export default gql`
  mutation revealPrivateKey(
    $basementSportsSecretKey: String
    $publicWalletAddress: String
  ) {
    revealPrivateKey(
      basementSportsSecretKey: $basementSportsSecretKey
      publicWalletAddress: $publicWalletAddress
    ) {
      privateKey
      successMessage
      errorMessage
    }
  }
`;
