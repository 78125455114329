import gql from "graphql-tag";

export default gql`
  mutation createTwoFactorVerification($type: String, $who: String) {
    createTwoFactorVerification(type: $type, who: $who) {
      successMessage
      errorMessage
    }
  }
`;
