import React, { useContext, useState } from "react";
import { Container } from "react-bootstrap";
export default function Notification({ type, notificationMessage }) {
  return (
    <Container
      className="mx-auto"
      style={{
        position: "fixed",
        top: "100px",
        color: "white",
        textAlign: "center",
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          backgroundColor: type == "success" ? "green" : "red",
          display: "inline-block",
          paddingTop: "5px",
          paddingBottom: "5px",
          paddingLeft: "15px",
          paddingRight: "15px",
          borderRadius: "5px",
        }}
      >
        {notificationMessage}
      </div>
    </Container>
  );
}
