import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Image,
  Row,
  Tab,
  Tabs,
  Dropdown,
  ButtonGroup,
  Table,
  Modal,
} from "react-bootstrap";
import "../utility/history.css";
import Notification from "../utility/Notification";
import { formatDistance } from "date-fns";
// Query
import FIND_AIRDROPS from "../query/findAirdropHistory.query";
// Mutation
import REVEAL_PRIVATE_KEY from "../mutation/nft/revealPrivateKey.mutation";

function History() {
  const [filterBy, setFilterBy] = useState("all");
  const [nftImageModalShow, setNftImageModalShow] = useState(false);
  const [selectedNftImages, setSelectedNftImages] = useState([]);
  // Reveal Private Key
  const [showRevealPrivateKeyModal, setShowRevealPrivateKeyModal] =
    useState(false);
  const [secretKey, setSecretKey] = useState("");
  const [
    selectedPublicAddressToRevealPrivateKey,
    setSelectedPublicAddressToRevealPrivateKey,
  ] = useState("");
  const [privateKeyThatRevealed, setPrivateKeyThatRevealed] = useState("");

  // Notification
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState("success");
  const [notificationMessage, setNotificationMessage] = useState("");

  // QUERY
  const { data, loading, error, refetch } = useQuery(FIND_AIRDROPS, {
    variables: {
      who: filterBy,
    },
  });

  // Mutation
  const [
    revealPrivateKey,
    { loading: loadingRevealPrivateKey, error: errorRevealPrivateKey },
  ] = useMutation(REVEAL_PRIVATE_KEY);

  useEffect(() => {
    refetch();
  }, []);

  async function revealPrivateKeyToShow() {
    const { data } = await revealPrivateKey({
      variables: {
        basementSportsSecretKey: secretKey,
        publicWalletAddress: selectedPublicAddressToRevealPrivateKey,
      },
    });
    if (data.revealPrivateKey.errorMessage == null) {
      setPrivateKeyThatRevealed(data.revealPrivateKey.privateKey);
    } else {
      notify("error", data.revealPrivateKey.errorMessage);
    }
  }

  function notify(successOrError, message) {
    setShowNotification(true);
    setNotificationType(successOrError);
    setNotificationMessage(message);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  }

  function coppyToClipBoard(textToCopy) {
    navigator.clipboard.writeText(textToCopy);
    notify("success", "Coppied to Clipboard");
  }

  if (loading) {
    return <h1>loading...</h1>;
  }

  return (
    <div>
      {showNotification && (
        <Notification
          type={notificationType}
          notificationMessage={notificationMessage}
        />
      )}
      {/* TABLE of History */}
      {data.findAirdropHistory.length > 0 && (
        <div
          style={{
            backgroundColor: "#363F55",
            width: "90%",
            margin: 0,
            borderRadius: "10px",
            color: "white",
            overflow: "scroll",
          }}
          className="mx-auto pt-4 pb-4 mt-5 historyContainer"
        >
          <h1 style={{ textAlign: "center" }}>Airdrop History</h1>
          <Table
            style={{
              color: "white",
              fontSize: "20px",
              width: "94%",
              margin: "auto",
              overflow: "scroll",
              textAlign: "center",
            }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Made By</th>
                <th>Total</th>
                <th>Receiver Mail</th>
                <th>To Pub Address</th>
                <th>To Private Key</th>
                <th>Collection</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {data.findAirdropHistory.map((item, index) => {
                return (
                  <tr key={index} style={{ fontSize: "16px" }}>
                    <td>{index + 1}</td>

                    <td>{item.who}</td>
                    <td>
                      {item.nftIds.length}{" "}
                      <i
                        style={{ color: "dodgerblue", fontSize: "80%" }}
                        className="onHoverCoursorChanged fa fa-file"
                        onClick={() => {
                          setSelectedNftImages(item.nftIdsDetails);
                          setNftImageModalShow(true);
                        }}
                      ></i>
                    </td>
                    <td>{item.receiverEmail}</td>
                    <td
                      className="onHoverCoursorChanged"
                      onClick={() => {
                        coppyToClipBoard(
                          item.finalPublicWalletAddressToSendNfts
                        );
                      }}
                    >
                      {item.finalPublicWalletAddressToSendNfts
                        ? `${item.finalPublicWalletAddressToSendNfts.slice(
                            0,
                            3
                          )}.....${item.finalPublicWalletAddressToSendNfts.slice(
                            -5
                          )}`
                        : "No data Found"}
                    </td>
                    <td>
                      {item.isExistCryptoEncyptedPrivateKey ? (
                        <i
                          className="fa fa-lock onHoverCoursorChanged"
                          onClick={() => {
                            setSelectedPublicAddressToRevealPrivateKey(
                              item.finalPublicWalletAddressToSendNfts
                            );
                            setShowRevealPrivateKeyModal(true);
                          }}
                        ></i>
                      ) : (
                        "Not found"
                      )}
                    </td>
                    <td>
                      {item.collectionType == "Male"
                        ? "Male-2022"
                        : "Female-2023"}
                    </td>
                    <td>
                      {formatDistance(new Date(), Number(item.createdAt))} ago
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}

      {/* NFT images MODAL */}
      <div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={nftImageModalShow}
          onHide={() => setNftImageModalShow(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: "center" }}>NFTs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {selectedNftImages.map((nft, index) => (
                <div className="py-1 px-0  col-sm-12 col-lg-4" key={index}>
                  <div
                    className="card shadow-none bg-dark mx-1 px-0"
                    style={{
                      alignItems: "center",
                      textAlign: "center",
                      height: "70%",
                    }}
                  >
                    <img
                      src={nft.url}
                      alt={nft.nftName}
                      height="130px"
                      width="130px"
                    />
                    <div className="card-body">
                      <h5 className="card-title text-white">{nft.nftName}</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* Reveal PRIVATE KEY Modal */}
      <div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showRevealPrivateKeyModal}
          onHide={() => {
            setPrivateKeyThatRevealed("");
            setShowRevealPrivateKeyModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: "center" }}>
              Reveal Private KEY
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  revealPrivateKeyToShow();
                }}
              >
                <Form.Group className="mb-3">
                  <Form.Label>Secret KEY</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Secret KEY"
                    value={secretKey}
                    onChange={(event) => setSecretKey(event.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Reveal Private KEY
                </Button>
              </Form>
            </div>
            {privateKeyThatRevealed && (
              <p
                className="mt-3"
                style={{ textAlign: "center" }}
                onClick={() => {
                  coppyToClipBoard(privateKeyThatRevealed);
                }}
              >
                Private Key:{" "}
                <b className="onHoverCoursorChanged">
                  {`${privateKeyThatRevealed.slice(
                    0,
                    5
                  )}.....${privateKeyThatRevealed.slice(-15)}`}
                </b>
              </p>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default History;
