import gql from "graphql-tag";

export default gql`
  mutation airdropMultipleNfts(
    $collectionType: String
    $verificationCode: Int!
    $who: String
    $toPublicKey: String
    $receiverEmail: String
    $nfts: [nftAvailabilityCheckObject]
    $giftMessage: String
    $dateOfBirth: String
  ) {
    airdropMultipleNfts(
      collectionType: $collectionType
      verificationCode: $verificationCode
      who: $who
      toPublicKey: $toPublicKey
      receiverEmail: $receiverEmail
      nfts: $nfts
      giftMessage: $giftMessage
      dateOfBirth: $dateOfBirth
    ) {
      successMessage
      errorMessage
    }
  }
`;
