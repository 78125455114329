import "../App.css";
import { useEffect, useState } from "react";
import gqlClient from "../gqlClient.js";
import { Row, Button, Form, Alert, Container } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

//graphQL
import LOG_IN from "../mutation/logIn.mutation";

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const USER = "userid";
  const TOKEN = "auth_token";

  //mutation
  const [logIn, { loading, error }] = useMutation(LOG_IN);

  const style = {
    title: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    rowLogin: {
      justifyContent: "center",
      textAlign: "left",
    },
  };

  function validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    // Valid format:  example@something.com, example@something.io
  }

  function validatePhone(phone) {
    // let reg = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{6})$/
    let reg = /^\+?\d+$/;
    let isphone = email.match(reg);

    if (isphone) {
      return true;
    } else {
      return false;
    }
  }

  async function logInFunc() {
    console.log("Come here to login");
    if (email != null && password != null) {
      // Email validation check
      const checkMail = validateEmail(email);
      const checkPhone = validatePhone(email);
      if (!checkMail && !checkPhone) {
        window.alert("error", "You typed invalid mail or phone number");
        return null;
      }

      const { data } = await logIn({
        variables: { email, password },
      });

      if (data.logIn.errorMessage) {
        window.alert(`${data.logIn.errorMessage}`);
      } else {
        localStorage.setItem(USER, data.logIn.user._id);
        navigate("/");
        // window.location.reload("/");
      }
    } else {
      window.alert("Please fill in email & password");
    }
  }

  useEffect(() => {
    if (localStorage.getItem("userid")) {
      navigate("/");
    }
  }, []);

  return (
    <div className="m-auto w-50">
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email Or Phone Number With Country Code</Form.Label>
          <Form.Control
            type="email"
            placeholder="a@example.com/ +0000000000"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
      </Form>

      <div
        style={{ justifyContent: "center" }}
        className="d-flex align-items-center "
      >
        <Button
          onClick={() => logInFunc()}
          variant="primary"
          type="submit"
          disabled={isLoading}
        >
          Login
        </Button>
      </div>
    </div>
  );
}

export default Login;
