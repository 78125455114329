import { gql } from "@apollo/client";

export default gql`
  query findANft($_id: String) {
    findANft(_id: $_id) {
      _id
      url
      isMinted
      _id
      serial
      mintPrice
      mintByDetails {
        _id
        name
        avatar
      }
      mintPrice
      mintPriceUnit
      createdAt
      errorMessage
    }
  }
`;
