import gql from "graphql-tag";

export default gql`
  mutation mintNft(
    $paymentMethod: String!
    $paymentBy: String!
    $nftId: String!
    $token: String
    $paymentResponse: String!
  ) {
    mintNft(
      paymentMethod: $paymentMethod
      paymentBy: $paymentBy
      nftId: $nftId
      token: $token
      paymentResponse: $paymentResponse
    ) {
      _id
      paymentMethod
      paymentFor
      paymentBy
      paymentByDetails {
        _id
        name
      }
      nftId
      nftDetails {
        _id
        url
      }
      amountToPay
      paidAmount
      currency
      paymentStatus
      paymentTransactionId
      createdAt
      errorMessage
    }
  }
`;
