import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";

import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import gqlClient from "./gqlClient.js";
import Login from "./auth/login";
import NavBar from "./navBar";
import Signup from "./auth/signup";
import VisaPayment from "./payment/visaPayment";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      {/* <NavBar /> */}
      <ApolloProvider client={gqlClient}>
        <Routes>
          <Route path="/" element={<App />} />
          {/* <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/mintNftWithVisa" element={<VisaPayment />} /> */}
        </Routes>
      </ApolloProvider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
