import gql from "graphql-tag";

export default gql`
  mutation signUp(
    $email: String!
    $password: String!
    $username: String!
    $avatar: String!
    $age: Int
    $city: String!
    $country: String!
  ) {
    signUp(
      email: $email
      password: $password
      username: $username
      avatar: $avatar
      age: $age
      city: $city
      country: $country
    ) {
      token
      errorMessage
      user {
        _id
        email
        name
        avatar
        age
        city
        country
        unreadNotificationCount
      }
    }
  }
`;
