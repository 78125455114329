// This is inactive account
// const STRIPE_CREDENTIALS = {
//   SECRET_KEY:
//     "sk_test_51LzwAbExPMOV92wnMe44VFnZfJhUrwjUThQE8u4fLixv2cd6sTjNfS9oOu6ME5wKbOqFq5dceibgbmfH0oxVy7y100pO3V7NkE",
//   PUBLISHABLE_KEY:
//     "pk_test_51LzwAbExPMOV92wntqVB29rLeCBkyhdqqcHZfTXYzQwJpUNsRLkBgR0SKhdkYyIdonWv968jQMFWrWVkKh6jk8fZ00IUlwqBvX",
//   CURRENCY: "USD",
// };

// This is basement sports officail account fot testing
// const STRIPE_CREDENTIALS = {
//   SECRET_KEY:
//     "sk_test_51HUYdrDMlw6iYeIlIxkLroorHjo0I14HdFt9Bls05oX1Q1OMYq9dzpX4ikyQdGx7u6lgxLyE18inb9U1uVA4FtJN00cWRFklSz",
//   PUBLISHABLE_KEY:
//     "pk_test_51HUYdrDMlw6iYeIlm17qAYWbYjHL5xFo6BHg3euOYXxsLG801358yNqlA7Vi127sv1f2NPtuZkGde6gRhSVs4mDQ00EPeSqsrW",
//   CURRENCY: "USD",
// };

// This is basement sports officail account for real data
const STRIPE_CREDENTIALS = {
  SECRET_KEY:
    "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",
  PUBLISHABLE_KEY:
    "pk_live_51HUYdrDMlw6iYeIlyY11qSumoJ0U8f8OXwxNJeTPMyasUkRjVfv1JOQagskc45YI4YedFysGHSuQap0GdNiUTxpj00UAY5iy8N",
  CURRENCY: "USD",
};

module.exports = {
  STRIPE_CREDENTIALS,
};
